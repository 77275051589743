
import AbpBase from "../../../lib/abpbase";
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import Campana from "../../../store/entities/OZONE/campana";
import {
  EstadoParticipacion,
  EstadoParticipacionTree,
} from "../../../store/entities/OZONE/estadoParticipacion";
import PageRequest from "../../../store/entities/page-request";
import SubEstadoParticipacion from "../../../store/entities/OZONE/subEstadoParticipacion";
class PageEstadoRequest extends PageRequest {}
class PageSubEstadoRequest extends PageRequest {
  Estadoid: number;
}
@Component
export default class selectEstado extends AbpBase {
  @Prop({ type: Object, default: null }) value: EstadoParticipacion;
  @Prop({ type: Boolean, default: true }) showPagination: Boolean;
  @Prop({ type: Boolean, default: false }) required: Boolean;
  @Prop({ type: Boolean, default: false }) disabled: Boolean;
  inputEstadoParticipacion: EstadoParticipacion = null;
  inputSubEstadoParticipacion: SubEstadoParticipacion = null;
  pagerequestEstado: PageEstadoRequest = new PageEstadoRequest();
  pagerequestSubEstado: PageSubEstadoRequest = new PageSubEstadoRequest();
  maxResultCountEstado = 10;
  maxResultCountSubEstado = 10;
  skipCountEstado = 0;
  skipCountSubEstado = 0;
  loading: boolean = false;
  get estadoList() {
    return this.$store.state.estadoParticipacion.list;
  }
  get estadoCount() {
    return this.$store.state.estadoParticipacion.totalCount;
  }
  get subestadoList() {
    return this.$store.state.subEstadoParticipacion.list;
  }

  async created() {
    await this.getEstadoDropDown().then(() => {
      return;
    });
  }

  async shown() {
    //await this.getEstadoDropDown()
  }

  async getSubEstados(params: any) {}
  async getEstados(params: any) {
    this.maxResultCountEstado = 10;
    this.skipCountEstado = 0;
    this.pagerequestEstado.maxResultCount = this.maxResultCountEstado;
    this.pagerequestEstado.skipCount = this.skipCountEstado;
    await this.$store.dispatch({
      type: "estadoParticipacion/getAll",
      data: this.pagerequestEstado,
    });
  }

  @Watch("value")
  async selectEstadoParticipacionChange(value) {
    if (value) {
      this.pagerequestSubEstado.Estadoid = value.id;
      this.pagerequestSubEstado.maxResultCount = this.maxResultCountSubEstado;
      this.pagerequestSubEstado.skipCount = this.skipCountSubEstado;

      await this.$store.dispatch({
        type: "subEstadoParticipacion/findAll",
        data: this.pagerequestSubEstado,
      });
    }
  }
  async nextPage(offset: number) {
    this.pagerequestEstado.skipCount = offset;
    await this.$store.dispatch({
      type: "estadoParticipacion/getAll",
      data: this.pagerequestEstado,
    });
  }
  async nextPageSubEstado(offset: number) {
    this.pagerequestSubEstado.skipCount = offset;
    await this.$store.dispatch({
      type: "subEstadoParticipacion/getAll",
      data: this.pagerequestSubEstado,
    });
  }

  async getEstadoDropDown() {
    
    this.pagerequestEstado.maxResultCount = this.maxResultCountEstado;
    this.pagerequestEstado.skipCount = this.skipCountEstado;
    await this.$store.dispatch({
      type: "estadoParticipacion/getAll",
      data: this.pagerequestEstado,
    });

    
  }

  estadoRule = {
    estado: {
      // required: { required: true },
      message: this.L("ThisFieldIsRequired", undefined, this.L("Estado")),
      placeholder: this.L("Estado"),
      trigger: "blur",
    },
  };
}
