
import { Component, Vue, Inject, Prop, Watch, Emit } from "vue-property-decorator";
import AbpBase from "../../lib/abpbase";
import Modal from "../Modal.vue";
import PageRequest from "../../store/entities/page-request";
import SelectCliente from "../../views/OZONE/cliente/selectCliente.vue";
import SelectExportacion from "../../views/OZONE/transferencia/selectExportacion.vue";
import SelectMarca from "../../views/OZONE/marca/selectMarca.vue";
import SelectCampana from "../../views/OZONE/campana/selectCampana.vue";
import Cliente from "../../store/entities/OZONE/cliente";
import Marca from "../../store/entities/OZONE/marca";
import SelectEstado from "../../views/OZONE/estadoParticipacion/selectEstado.vue";
import SelectSubEstado from "../../views/OZONE/subEstadoParticipacion/selectSubEstado.vue";
class PageCampanaRequest extends PageRequest {
  keyword: string;
  fechaInicio: Date;
  fechaFin: Date;
  cliente: Cliente;
  marca: Marca;
}

@Component({
  components: {
    Modal,
    SelectCliente,
    SelectMarca,
    SelectCampana,
    SelectExportacion,
    SelectEstado,
    SelectSubEstado
  },
})
export default class PeopleFind extends AbpBase {
  name: "people-find";
  @Prop({ type: Boolean, default: false }) value: boolean;
  @Prop({ type: Boolean, default: false }) showSettingsDrawer: boolean;
  @Prop({ type: String }) id: string;
  @Prop({ type: String }) title: string;
  @Prop({ type: Array }) model: { value; fieldBind; fieldType; fieldName }[];
  @Prop() pagerequest: PageCampanaRequest;

  cliente: Cliente = null;
  wrapper: any;

  async created() {
    this.wrapper = document.body;
  }
  save() {
    
    this.$emit("toggleSettingsDrawer");
    this.$emit("find-success", this.model);
    this.$emit("input", false);
    this.$store.commit("participacion/guardarbusqueda", this.model);
    this.resetFields();
  }

  reset() {
    
    this.model.forEach((element) => {
      element.value = null;
    });
  this.$store.commit("participacion/guardarbusqueda", this.model);
    this.$emit("find-success", this.model);
    
   
  }

  resetField(key) {
    
    this.model.forEach((element) => {
        if(element.fieldBind == key){
            element.value = null;
        }
    });
     this.$store.commit("participacion/guardarbusqueda", this.model);  
    this.$emit("find-success", this.model);
  
   

  }

  close() {
    this.$emit("input", false);
    this.resetFields();
  }
  async shown() {}

  resetFields() {}
}
