
import AbpBase from "../../../lib/abpbase";
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import {
  EstadoParticipacion,
  EstadoParticipacionTree,
} from "../../../store/entities/OZONE/estadoParticipacion";
import PageRequest from "../../../store/entities/page-request";
import SubEstadoParticipacion from "../../../store/entities/OZONE/subEstadoParticipacion";

class PageSubEstadoRequest extends PageRequest {
  nombre: string;
}
@Component
export default class selectSubEstado extends AbpBase {
  @Prop({ type: Object, default: null }) value: SubEstadoParticipacion;
  @Prop({ type: Boolean, default: true }) showPagination: Boolean;
  @Prop({ type: Boolean, default: false }) required: Boolean;
  @Prop({ type: Boolean, default: false }) disabled: Boolean;

  pagerequestSubEstado: PageSubEstadoRequest = new PageSubEstadoRequest();
  maxResultCountEstado = 10;
  maxResultCountSubEstado = 10;
  skipCountEstado = 0;
  skipCountSubEstado = 0;
  loading: boolean = false;
  get subestadoList() {
    return this.$store.state.subEstadoParticipacion.list;
  }
  get subestadoCount() {
    return this.$store.state.subEstadoParticipacion.totalCount;
  }

  created() {}

  async shown() {
    //await this.getEstadoDropDown()
  }
  async getSubEstados() {}
  async nextPageSubEstado(offset: number) {
    this.pagerequestSubEstado.skipCount = offset;
    await this.$store.dispatch({
      type: "subEstadoParticipacion/getAll",
      data: this.pagerequestSubEstado,
    });
  }

  @Watch("subestadoCount")
  selectEstadoParticipacionChange() {
    if (this.value) {
      this.value.id = 0;
      this.value.nombre = "Motivo";
    }
  }

  subestadoRule = {
    subestado: {
      // required: { required: true },
      message: this.L("ThisFieldIsRequired", undefined, this.L("Motivo")),
      placeholder: this.L("Motivo"),
      trigger: "blur",
    },
  };
}
