
import AbpBase from '../../../lib/abpbase'
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import Exportacion from '../../../store/entities/OZONE/exportacion'
import PageRequest from '../../../store/entities/page-request'
class PageCampanaRequest extends PageRequest {
  nombre: string
}

@Component
export default class SelectExportacion extends AbpBase {
  @Prop({ type: Object, default: null }) value: Exportacion
  @Prop({ type: Boolean, default: true }) showPagination: Boolean
  @Prop({ type: Boolean, default: false }) required: Boolean
  @Prop({ type: Boolean, default: false }) disabled: Boolean
  exportacion: Exportacion = null
  pagerequestCampanas: PageCampanaRequest = new PageCampanaRequest()
  maxResultCountCampana = 10
  skipCountCampana = 0

  get campanaList() {
    return this.$store.state.exportacion.list
  }
  get campanaCount() {
    return this.$store.state.exportacion.totalCount
  }

  async created() {
    await this.getCampanasDropDown().then(() => {
      return
    })
  }

  async shown() {
    //await this.getCampanasDropDown()
  }

  async getCampanas(params: any) {
    this.pagerequestCampanas.nombre = params
    this.maxResultCountCampana = 10
    this.skipCountCampana = 0
    this.pagerequestCampanas.maxResultCount = this.maxResultCountCampana
    this.pagerequestCampanas.skipCount = this.skipCountCampana
    await this.$store.dispatch({
      type: 'exportacion/getAll',
      data: this.pagerequestCampanas
    })
  }

  async nextPage(offset: number) {
    this.pagerequestCampanas.skipCount = offset
    await this.$store.dispatch({
      type: 'exportacion/getAll',
      data: this.pagerequestCampanas
    })
  }

  async getCampanasDropDown() {
    this.pagerequestCampanas.maxResultCount = this.maxResultCountCampana
    this.pagerequestCampanas.skipCount = this.skipCountCampana
    await this.$store.dispatch({
      type: 'exportacion/getAll',
      data: this.pagerequestCampanas
    })
  }

  campanaRule = {
    campana: {
      // required: { required: true },
      message: this.L('ThisFieldIsRequired', undefined, this.L('Exportacion')),
      placeholder: this.L('Exportacion'),
      trigger: 'blur'
    }
  }
}
